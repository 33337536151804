import { gql } from '@apollo/client';

export const PRODUCT_OPTIONS_FRAGMENT_LITE = gql`
  fragment ProductOptionsFragmentLite on Product {
    options {
      id
      product_id
      name
      default_value
      popular_values
      values {
        variant_id
        option_values {
          id
          sku
          value
          additional_cost
          variant_id
          is_active
          additional_visibility
        }
      }
      totalValuesCount
    }
  }
`;

export const PRODUCT_GRID_FRAGMENT_LITE = gql`
  fragment ProductGridFragmentLite on Product {
    id
    slug
    default_option_value
    default_option_image
    efg_flag
    is_external
    name
    price
    sku
    exp_wood_product
    type {
      name
    }
    family {
      name
    }
    type {
      name
    }
    attributes {
      code
      label
      name
      value
    }
    hero_images_min {
      sort_order
      url
    }
    hero_image_count
    images {
      sort_order
      url
    }
    meta {
      id
      customer {
        favorited
        favOptions {
          optionName
          optionId
          optionType
        }
      }
      price(stateCode: $stateCode, regionId: $regionId) {
        discount_percent
        final_price
        original_price
        max_discount_percent
      }
      shipping {
        quick_ship
      }
    }
    ...ProductOptionsFragmentLite
  }

  ${PRODUCT_OPTIONS_FRAGMENT_LITE}
`;
const PRODUCT_GRID_FRAGMENT_OPTIONS_EXTRA = gql`
  fragment ProductGridFragmentOptionsExtra on Product {
    id
    slug
    default_option_value
    default_option_image
    efg_flag
    is_external
    name
    price
    sku
    type {
      name
    }
    family {
      name
    }
    type {
      name
    }
    attributes {
      code
      label
      name
      value
    }
    hero_images_min {
      sort_order
      url
    }
    hero_image_count
    images {
      sort_order
      url
    }
    meta {
      id
      customer {
        favorited
        favOptions {
          optionName
          optionId
          optionType
        }
      }
      price(stateCode: $stateCode, regionId: $regionId) {
        discount_percent
        final_price
        original_price
        max_discount_percent
      }
      shipping {
        quick_ship
      }
    }
    ...ProductOptionsFragmentLite
  }

  ${PRODUCT_OPTIONS_FRAGMENT_LITE}
`;

const CATEGORY_PAGE_FRAGMENT_LITE = gql`
  fragment CategoryPageFragmentLite on Category {
    id
    imageURL
    slug
    name
    products {
      ...ProductGridFragmentOptionsExtra
    }
    totalProductsCount
  }

  ${PRODUCT_GRID_FRAGMENT_OPTIONS_EXTRA}
`;

const PLPProductFragment = gql`
  fragment PLPProductFragment on PLPProduct {
    attributes {
      id
      values
    }
    id
    name
    path
    productFamily
    productLabel
    productType
    quickship
    rangeAttributes {
      id
      value
    }
    secondaryImage
    secondaryImageCropData
    slug
    sortOrder {
      featured
      newest
      popularity
    }
    variants {
      image
      label
      minPrice
      originalPrice
      path
      price
      quickship
      isOutOfStock
      sku
      slug
      tier
    }
    variantsTotal
  }
`;

export const GET_CATEGORY = gql`
  query getCategory(
    $slug: String
    $offset: Int
    $limit: Int
    $filters: ProductFiltersInput
    $sortBy: ProductsSortByEnum
    $stateCode: String
    $regionId: String
  ) {
    category: getCategory(
      slug: $slug
      offset: $offset
      limit: $limit
      filters: $filters
      sortBy: $sortBy
    ) {
      ...CategoryPageFragmentLite
      category_description
      category_label
      category_page_title
      category_meta_description
      category_keywords
      childCategories {
        ...CategoryPageFragmentLite
      }
      filter_style
      filter_material
      filter_leg
      filter_cushion
      filterRangeData {
        width {
          min
          max
        }
        height {
          min
          max
        }
        depth {
          min
          max
        }
        seat_width {
          min
          max
        }
        seat_height {
          min
          max
        }
        seat_depth {
          min
          max
        }
      }
      filtersConfig {
        leg_style {
          Straight
          Flared
          Ball
          Square
        }
        material_filter {
          fabric
          leather
          wood
        }
        style_filter {
          midcentury
          modern
          transitional
        }
        cushion_style {
          attached
          removable
          reversible
          tufted
          downalt
        }
        quickship_filter
      }
      images {
        banner_3 {
          image
        }
        promo_banner {
          image
        }
        url_banner {
          image
          pid_param
        }
        global_banner {
          glo_banner
        }
      }
      menu {
        root {
          name
          slug
        }
        children {
          name
          slug
        }
      }
    }
  }

  ${PRODUCT_GRID_FRAGMENT_OPTIONS_EXTRA}

  ${CATEGORY_PAGE_FRAGMENT_LITE}
`;

export const GET_PDP_FROM_THE_COLLECTION = gql`
  query getCollection(
    $slug: String
    $offset: Int
    $limit: Int
    $filters: ProductFiltersInput
    $sortBy: ProductsSortByEnum
    $forProduct: Int
    $stateCode: String
    $regionId: String
  ) {
    collection: getCollection(
      slug: $slug
      filters: $filters
      sortBy: $sortBy
      forProduct: $forProduct
    ) {
      id
      imageURL
      slug
      name
      products(offset: $offset, limit: $limit) {
        ...ProductGridFragmentLite
      }
    }
  }

  ${PRODUCT_GRID_FRAGMENT_LITE}
`;

export const GET_COLLECTIONS = gql`
  query getCollections($filters: ProductFiltersInput) {
    getCollections(filters: $filters) {
      collections {
        id
        url: slug
        name
        category_label
        category_description
        category_meta_description
        products {
          id
          name
          type {
            name
          }
          images_min {
            url
          }
          hero_images_min {
            url
          }
          ...ProductOptionsFragmentLite
        }
      }
      filtersConfig {
        leg_style {
          Straight
          Flared
          Ball
          Square
        }
        style_filter {
          midcentury
          modern
          transitional
        }
        cushion_style {
          attached
          removable
          reversible
          tufted
          downalt
        }
      }
    }
  }

  ${PRODUCT_OPTIONS_FRAGMENT_LITE}
`;

export const GET_ALL_REVIEWS = gql`
  query getAllReviews($sortBy: String, $offset: Int, $limit: Int) {
    allReviews: getAllReviews(sortBy: $sortBy, offset: $offset, limit: $limit) {
      product_id
      product_name
      product_url
      product_image
      reviews {
        id
        title
        full_name
        comment
        rate
        date
      }
      total_reviews
      options {
        name
        default_value
        popular_values
        values {
          variant_id
          option_values {
            id
            sku
            value
            additional_cost
            variant_id
          }
        }
        totalValuesCount
      }
    }
  }
`;

export const GET_REVIEWS_META = gql`
  query getReviewsMeta {
    reviewsMeta: getReviewsMeta {
      average_score
      count
    }
  }
`;

export const GET_RECENTLY_VIEWED = gql`
  query getRecentlyViewed {
    getRecentlyViewed
  }
`;

export const GET_CATEGORY_TOP_SALES_PRODUCTS = gql`
  query getCategoryTopSalesProducts($id: Int) {
    getCategoryTopSalesProducts(id: $id) {
      product_id
      category_id
      position
    }
  }
`;

export const GET_PRODUCT_MINIMAL_LAYOUT = gql`
  query getProduct(
    $slug: String
    $id: Int
    $productSku: String
    $stateCode: String
    $regionId: String
  ) {
    product: getProduct(slug: $slug, id: $id, productSku: $productSku) {
      id
      sku
      slug
      name
      default_option_value
      default_option_image
      price
      efg_flag
      is_external
      upholstery_product {
        attributes_min {
          urlKey
        }
      }
      attributes {
        code
        label
        name
        value
      }
      hero_images_min {
        sort_order
        url
        img {
          url
          cropData
        }
      }
      images {
        sort_order
        url
      }
      family {
        name
      }
      type {
        name
      }
      meta {
        id
        active
        inventory_count
        manufacturer
        config {
          fabric_count
          has_cushion
          has_mattress
          has_orientation
          stain_count
        }
        customer {
          favorited
          favOptions {
            optionName
            optionId
            optionType
          }
          is_designer
        }
        gallery {
          construction_count
          customer_photo_count
          hero_count
          variation_count
        }
        price(stateCode: $stateCode, regionId: $regionId) {
          discount_percent
          final_price
          financing_month
          original_price
          max_discount_percent
        }
        promotion {
          active
          template
          type
          startDate
          endDate
          discount
        }
        shipping {
          lead_time
          quick_ship
          variants {
            id
            lead_time
            bucket_lead_time
            qty
            quick_ship
            variant
            title
            description
            icon
            image
            sku
          }
        }
      }
      ...ProductGridFragmentLite
    }
  }

  ${PRODUCT_GRID_FRAGMENT_LITE}
`;

export const GET_FAVORITED_PRODUCTS = gql`
  query getFavoritedProducts {
    products: getFavoritedProducts {
      product {
        id
        name
        meta {
          id
          customer {
            favorited
            favOptions {
              optionName
              optionId
              optionType
            }
          }
        }
      }
      favOption {
        optionName
        optionId
        optionType
      }
    }
  }
`;

export const GET_FAVORITED_PRODUCT_IDS = gql`
  query getFavoritedProductIds {
    productIds: getFavoritedProductIds
  }
`;

export const GET_FAVORITED_PRODUCTS_FULL = gql`
  query getFavoritedProducts($stateCode: String, $regionId: String) {
    products: getFavoritedProducts {
      product {
        ...ProductGridFragmentLite
      }
      favOption {
        optionName
        optionId
        optionType
      }
    }
  }
  ${PRODUCT_GRID_FRAGMENT_LITE}
`;

export const GET_ORDER_INFO = gql`
  query getOrderInfo($order_id: String) {
    getOrderInfo(order_id: $order_id) {
      errors {
        code
        field
        message
      }
      order {
        order_id
        order_subtotal
        cart_protection_subtotal
        coupon_discount
        addditional_services
        tax_charge
        gift_used
        gift_card_rewards_used
        total_order_amount
        customer {
          customer_id
          customer_email
          customer_first_name
          customer_last_name
        }
        shipping {
          address_first_name
          address_last_name
          address_street1
          address_street2
          address_company
          address_city
          address_state
          address_zip_code
          address_country
          address_phone
        }
        billing {
          address_first_name
          address_last_name
          address_street1
          address_street2
          address_company
          address_city
          address_state
          address_zip_code
          address_country
          address_phone
        }
        payment {
          card_no
          payment_type
          payment_token
          payment_amount
        }
        order_items {
          name
          product_id
          product_slug
          order_cart_id
          discount
          discounted_cost
          quantity
          total_cost
          delivery_method
          production_method
          shipping_group
          sizes_text
          sku
          variants_sku
          pickLater
          product_options
          estimated_delivery
          estimated_delivery_title
          imageURL
          options {
            id
            name
            value
            image
            optionTypeId
            optionTypeName
            default_value
            additional_cost
          }
          selected_protection_plan {
            plan_id
            plan_name
            plan_price
            plan_percent
            plan_description
          }
        }
      }
    }
  }
`;

export const GET_ORDER_UPCHARGE_SUMMARY = gql`
  query getOrderItemUpChargeSummary($input: OrderItemUpChargeSummaryInput) {
    getOrderItemUpChargeSummary(input: $input) {
      errors {
        code
        field
        message
      }
      methods {
        cardType
        cardNumber
        payment_name
        payment_token
        payment_provider
      }
      summary {
        cartSubtotal_diff
        discount
        tax
        grand_total_diff
        grand_total
      }
    }
  }
`;

export const GET_CHARGE_INFO = gql`
  query getChargeInfo {
    chargeInfo: getChargeInfo {
      tax
      shipping
    }
  }
`;

export const GET_CURRENCY_SELECTED = gql`
  query getCurrencySelected {
    currencySelected: getCurrencySelected {
      name
      symbol
    }
  }
`;

export const GET_CART_DELIVERY_DATES = gql`
  query getDeliveryDates($input: CheckoutSummaryInput, $visitorZip: String) {
    getDeliveryDates(input: $input, visitorZip: $visitorZip) {
      errors {
        code
        field
        message
      }
      delivery_dates {
        transitTime
        etaShipping {
          start
          end
          short_format
        }
        etaDelivery {
          start
          end
          short_format
        }
      }
    }
  }
`;

export const GET_CART_SHIPPING_GROUPS = gql`
  query getShippingGroups(
    $input: CheckoutSummaryInput
    $visitorZip: String
    $checkoutRef: String
  ) {
    getShippingGroups(
      input: $input
      visitorZip: $visitorZip
      checkoutRef: $checkoutRef
    ) {
      errors {
        code
        field
        message
      }
      shippingGroups {
        id
        cart {
          id
          product_id
          slug
          name
          eligible_protection_plans {
            plan_id
            plan_name
            plan_price
            plan_percent
            plan_description
          }
          selected_protection_plan {
            plan_id
            plan_price
          }
          product {
            sku
            efg_flag
            family {
              name
            }
            type {
              name
            }
            meta {
              id
              customer {
                favorited
              }
              shipping {
                quick_ship
              }
            }
          }
          sku
          quantity
          imageURL
          description
          price
          price_pre_discount
          price_post_discount
          discount_percentage
          discount_amount
          pickLater
          options {
            id
            name
            value
            image
            optionTypeId
            optionTypeName
            default_value
          }
          attributes_min {
            dimensions
          }
          stock_discount
        }
        sort_priority
        delivery_options {
          label
          value
          charge
          status
          default_selection
          is_selected
        }
        production_options {
          label
          value
          charge
          production_time
          status
        }
        accessory_options {
          label
          value
          charge
          status
        }
        delivery_dates {
          transitTime
          etaShipping {
            start
            end
            short_format
          }
          etaDelivery {
            start
            end
            short_format
          }
        }
      }
      deliveryInfo {
        delivery_selection
        production_selection
        production_selection_add
      }
    }
  }
`;

export const GET_CART = gql`
  query getCart($zipCode: String, $shippingZip: String, $checkoutRef: String) {
    getCart(
      zipCode: $zipCode
      shippingZip: $shippingZip
      checkoutRef: $checkoutRef
    ) {
      errors {
        code
        field
        message
      }
      cart_token
      cart {
        id
        product_id
        slug
        flash_sale
        is_clearance
        name
        eligible_protection_plans {
          plan_id
          plan_name
          plan_price
          plan_percent
          plan_description
        }
        selected_protection_plan {
          plan_id
          plan_name
          plan_price
        }
        product {
          sku
          family {
            name
          }
          type {
            name
          }
          meta {
            id
            customer {
              favorited
            }
            shipping {
              quick_ship
            }
          }
          clearance_stock(zipCode: $zipCode)
          upholstery_product {
            layout_widgets {
              widget_identifier
              fields
            }
          }
          custom_properties {
            display_option_images
          }
          efg_flag
          exp_wood_product
          exclude_from_sale
          attributes_min {
            urlKey
          }
          attributes {
            code
            value
          }
        }
        sku
        quantity
        imageURL
        description
        price
        price_pre_discount
        price_post_discount
        discount_percentage
        discount_amount
        stock_discount
        pickLater
        options {
          id
          name
          value
          image
          optionTypeId
          optionTypeName
          default_value
          additional_cost
          exclude_from_sale
          sku
        }
        cart_item_errors
        coupon_applied
      }
    }
  }
`;

export const GET_NAVBAR_MENUS = gql`
  query getNavigationMenus {
    getNavigationMenus {
      learn
      shop
    }
  }
`;

export const GET_CUSTOMER_ORDERS = gql`
  query getCustomerOrders($page: Int, $limit: Int) {
    getCustomerOrders(page: $page, limit: $limit) {
      errors {
        code
        field
        message
      }
      orders {
        order_id
        order_state_type
        dated
        dated_f
        total_order_amount
        order_status
        shipment_status
        address_id
        address_first_name
        address_last_name
        address_company
        address_street1
        address_street2
        address_city
        address_country
        address_state
        address_zip_code
        address_phone
        address_fax
        delivery_date
        estimated_delivery
        no_fabric
        order_items {
          name
          price
          cart_id
          product_id
          url_key
          discount
          total_cost
          discounted_cost
          wood_stain
          upholstery
          purchase_date
          small_image
          small_image_2
          thumbnail
          quantity
          fabric_reqirements
          tracking_url
          tracking_carrier
          eta_delivery_start
          eta_delivery_end
          efg_flag
          option_value_ids
          variants_sku
          sku
          imageURL
          pickLater
          options {
            id
            name
            value
            image
            optionTypeId
            optionTypeName
            default_value
          }
          review_exists
          photos_exists
          shipping_group
          delivery_method
          estimated_shipping
          estimated_delivery
          estimated_delivery_title
          shipped_date
          is_shipped
          delivered_date
          is_delivered
          available_protection_plans {
            plan_id
            plan_name
            plan_price
            plan_description
          }
          applied_protection_plan {
            plan_id
            plan_name
            plan_price
            plan_description
          }
          stock_discount
          group_cleaning_code
          no_fabric
        }
      }
    }
  }
`;

export const GET_ACCOUNT_INFO = gql`
  query getAccountInfo {
    getAccountInfo {
      success
      message
      email
      first_name
      last_name
      phone
      phone2
      preferred_contact
      street1
      street2
      city
      state
      zip
      country
      company
      jb_customer_id
      is_designer
    }
  }
`;

export const GET_TREE_COUNT = gql`
  query getTreeCount {
    trees: getTreeCount
  }
`;

export const GET_ACTIVE_PROMOTION_BANNER = gql`
  query getActivePromotionBanner {
    promotion: getActivePromotionBanner {
      id
      name
      discount
      mainContent
      subContent
      endDate
      countDownFormat
      countdownToggle
      backgroundImage
      backgroundColor
      backgroundHeight
      secondaryBannerText
      textColor
    }
  }
`;

export const GET_NEWEST_ARRIVALS = gql`
  query getNewestArrivals {
    products: getNewestArrivals {
      id
      name
      slug
      type {
        name
      }
      attributes {
        code
        value
      }
      images {
        sort_order
        url
      }
      hero_images {
        sort_order
        url
      }
      ...ProductOptionsFragmentLite
    }
  }

  ${PRODUCT_OPTIONS_FRAGMENT_LITE}
`;

export const GET_CUSTOMER_REWARDS_ACTIVITY = gql`
  query getCustomerRewardsActivity {
    rewardsActivity: getCustomerRewardsActivity {
      activities {
        id
        coupon {
          coupon_code
          coupon_name
          coupon_type
          discount_amt
          start_date
          end_date
          gift_card_balance
        }
        reward_type
        reward_amount
        activities {
          order_id
          disc_amount
        }
      }
      summary {
        total_earned
        total_left
        total_spent
      }
      errors {
        message
        field
        code
      }
    }
  }
`;

export const GET_FABRIC_DATA = gql`
  query getFabrics(
    $fabricValueId: String
    $filters: FabricFilterInput
    $activeOnly: Boolean
  ) {
    getFabrics(
      fabricValueId: $fabricValueId
      filters: $filters
      activeOnly: $activeOnly
    ) {
      option_id
      option_name
      option_value_id
      option_sku
      option_value
      option_image
      option_color
      option_description
      pet_friendly
      pantone_flag
      exclude_from_sale
      performance_fabric
      sustainable_fabric
      option_value_tier
      option_group_id
      group_name
      sort_order
      is_active
      group_material
      group_cleaning_code
      group_material_desc
    }
  }
`;
export const GET_FABRIC_GALLERY_DATA = gql`
  query getFabricGalleryData($fabricValueId: Int, $fromCache: Boolean) {
    getFabricGalleryData(fabricValueId: $fabricValueId, fromCache: $fromCache)
  }
`;

export const GET_FABRIC_REVIEWS = gql`
  query getFabricReviews($fabricValueId: String!) {
    getFabricReviews(fabricValueId: $fabricValueId) {
      rating
      reviews {
        date
        review
        rate
        location
        full_name
      }
    }
  }
`;

export const GET_SITEMAP_LINKS = gql`
  query {
    sitemap_links: getSitemapLinks {
      category_links
      customer_service_links
      other_links
    }
  }
`;

export const GET_FABRICS_BY_IDS = gql`
  query getFabricsByIds($fabricValueIds: [String]) {
    fabricData: getFabricsByIds(fabricValueIds: $fabricValueIds) {
      option_id
      option_sku
      option_name
      option_value_id
      option_value
      option_image
      option_color
      option_description
    }
  }
`;

export const GET_ORDER_BUILD_UPDATES = gql`
  query getOrderBuildUpdates($order_id: String) {
    buildUpdates: getOrderBuildUpdates(order_id: $order_id) {
      errors {
        code
        field
        message
      }
      build_updates {
        ets_date
        initial {
          completed
          label
          date
        }
        preparation {
          completed
          label
          date
        }
        build {
          completed
          label
          date
        }
        ship {
          completed
          label
          date
        }
        deliver {
          completed
          label
          date
        }
      }
    }
  }
`;

export const GET_CUSTOMER_REVIEWS = gql`
  query getCustomerReviews($page: Int, $limit: Int) {
    customerReviews: getCustomerReviews(page: $page, limit: $limit) {
      errors {
        code
        field
        message
      }
      reviews {
        order_id
        dated
        fk_store_id
        total_order_amount
        order_status
        estimated_delivery
        order_items {
          cart_id
          upholstery
          product_id
          product_image
          name
          review {
            review_id
            review_title
            review_comment
            review_rate
            review_status
            fabric_review {
              review_rate
              review_sentence
              is_active
            }
          }
          photos
        }
      }
    }
  }
`;

export const GET_CLEARANCE_DATA = gql`
  query getClearanceData(
    $zipCode: String
    $fromCache: Boolean
    $warehouseId: String
  ) {
    clearanceData: getClearanceData(
      zipCode: $zipCode
      fromCache: $fromCache
      warehouseId: $warehouseId
    ) {
      data
      errors {
        code
        field
        message
      }
    }
  }
`;

export const GET_ALL_PRODUCT_TYPES = gql`
  {
    productTypes: getAllProductTypes
  }
`;

export const GET_MODULAR_SOFA_COLLECTIONS_STATIC = gql`
  query getModularSofaCollectionsStatic {
    collections: getModularSofaCollectionsStatic {
      primaryProduct {
        ...PLPProductFragment
      }
      modularCollectionSlug
      products {
        ...PLPProductFragment
      }
      additionalAttributes {
        showpieceTitle
        showpieceDesc
        showpieceImg
      }
      totalProductsCount
    }
  }

  ${PLPProductFragment}
`;

export const GET_ALL_PRODUCTS_TREE_COUNT = gql`
  query getAllProductTreeCounts {
    getAllProductTreeCounts {
      tree_counts
    }
  }
`;

export const GET_PRODUCT_VISUALIZATION_OPTIONS = gql`
  query getProductVisOptions($id: Int, $slug: String) {
    productVisOptions: getProductVisOptions(id: $id, slug: $slug) {
      id
      name
      display_vis_block
      price
      type {
        name
      }
      images {
        url
      }
      attributes {
        code
        label
        name
        value
      }
      options: options_extended {
        name
        default_value
        values {
          option_values {
            id
            sku
            color
            value
            image
            pet_friendly
          }
        }
      }
    }
  }
`;

export const GET_IS_PRODUCT_FAVOURITE = gql`
  query getProductCustomerMeta($id: Int) {
    getProductCustomerMeta(id: $id) {
      favorited
    }
  }
`;

export const GET_JOYBIRD_INSTAGRAM_IMAGES = gql`
  query getJoybirdInstagramImages($count: Int) {
    getJoybirdInstagramImages(count: $count) {
      images {
        id
        src
        height
        width
        caption
      }
    }
  }
`;

export const GET_BRAINTREE_CLIENT_TOKEN = gql`
  query getBraintreeClientToken(
    $email: String
    $first_name: String
    $last_name: String
    $grand_total: Float
    $quick_checkout_guest: Boolean
  ) {
    token: getBraintreeClientToken(
      email: $email
      first_name: $first_name
      last_name: $last_name
      grand_total: $grand_total
      quick_checkout_guest: $quick_checkout_guest
    ) {
      success
      message
      clientToken
    }
  }
`;

export const GET_CHECKOUT_SUMMARY = gql`
  query getCheckoutSummary($input: CheckoutSummaryInput, $checkoutRef: String) {
    checkoutSummary: getCheckoutSummary(
      input: $input
      checkoutRef: $checkoutRef
    ) {
      errors {
        code
        field
        message
      }
      cart_subtotal
      base_discount_amount
      protectionSubtotal
      totalProtectionPlans
      discount
      total_shipping
      delivery_charge
      production_charge
      accessory_delivery_charge
      tax_amount
      tax_percent
      tax_customer_exempt
      tax_ship_exempt
      credit_total
      credit_used
      credit_balance
      gift_total
      gift_used
      gift_balance
      gift_details {
        coupon_id
        coupon_code
        amount_used
        amount_balance
        amount_available
      }
      gift_card_rewards_total
      gift_card_rewards_used
      applied_coupons {
        type
        coupon_id
        coupon_code
        amount_used
        amount_balance
        amount_available
      }
      stock_discount
      promotionName
      grand_total
      cart_token
    }
  }
`;

export const GET_INSTORE_CHECKOUT_DATA = gql`
  query getInstoreCheckoutData($checkoutRef: String) {
    getCheckoutData(checkoutRef: $checkoutRef)
  }
`;

export const GET_OPTION_BY_ID = gql`
  query getOptionValueById($optionValueId: Int) {
    optionValue: getOptionValueById(optionValueId: $optionValueId) {
      id
      value
      sku
    }
  }
`;

export const GET_PROTECTION_PLAN_TYPES = gql`
  query getProtectionPlanTypes {
    getProtectionPlanTypes {
      errors {
        code
        message
      }
      plans {
        plan_id
        plan_name
        plan_description
        plan_percent
      }
    }
  }
`;

export const GET_ORDER_BY_ORDER_ID_AND_EMAIL = gql`
  query getOrderByOrderIdAndEmail($order_id: Int!, $email: String!) {
    getOrderByOrderIdAndEmail(order_id: $order_id, email: $email) {
      errors {
        code
        message
      }
      order {
        order_id
        order_type
        order_status
        payment_status
        delivery_date
        total_order_amount
        order_cart {
          order_cart_id
          item_condition
          quantity
          custom_description
          delivered_date_cart
          delivery_method
          catalog_product {
            product_id
            fk_store_id
            fk_attribute_set_id
            fk_type_id
          }
        }
      }
      orderItems
      orderCartIssues {
        cartId
        categories {
          id
          title
          index
          issues {
            id
            title
            index
            components {
              id
              title
              index
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_FROM_COORDS = gql`
  query getLocationFromCoords($latitude: Float!, $longitude: Float!) {
    getLocationFromCoords(latitude: $latitude, longitude: $longitude) {
      zip
      address
      status
      state
      stateShortName
      city
      country
    }
  }
`;

export const GET_LOCATION_FROM_ZIPCODE = gql`
  query getLocationFromZip($zipcode: SanitizedZipCodeInputString!) {
    getLocationFromZip(zipcode: $zipcode) {
      zip
      address
      status
      state
      stateShortName
      city
      country
    }
  }
`;

export const GET_STORE_EMPLOYEES = gql`
  query getStoreEmployees($storeId: String) {
    storeEmployees: getStoreEmployees(storeId: $storeId) {
      entered_by
      employees {
        id
        real_name
      }
      designers {
        real_name
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const GET_STORE_POS_READERS = gql`
  query getStorePOSReaders($storeId: String!) {
    storeReaders: getStorePOSReaders(storeId: $storeId) {
      readers {
        id
        name
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const GET_STORE_POS_CONTEXT = gql`
  query getStorePOSContext($email: String!, $inStoreContextId: String!) {
    context: getStorePOSContext(
      email: $email
      inStoreContextId: $inStoreContextId
    ) {
      inStoreContext {
        id
        status
        transaction {
          id
          status
        }
      }
      errors {
        code
        field
        message
      }
    }
  }
`;

export const GET_RECOMMENDED_PRODUCTS = gql`
  query getRecommendedProducts(
    $tuID: String
    $limit: Int
    $refreshCache: Boolean
  ) {
    getRecommendedProducts(
      tuID: $tuID
      limit: $limit
      refreshCache: $refreshCache
    ) {
      sku
      score
    }
  }
`;

export const GET_ITEM_TO_ITEM_RECOMMENDATION = gql`
  query getItemToItemRecommendation($id: Int!, $limit: Int) {
    getItemToItemRecommendation(id: $id, limit: $limit) {
      id
      rank
    }
  }
`;

export const GET_MULTI_ITEM_RECOMMENDATION = gql`
  query getMultiItemToItemRecommendation($products: [ItemRecommendationInput]) {
    getMultiItemToItemRecommendation(products: $products)
  }
`;

export const GET_WAREHOUSE_ID_FOR_ZIPCODE = gql`
  query getWarehouseIdFromZip($zipcode: String!) {
    getWarehouseIdFromZip(zipcode: $zipcode) {
      warehouseId
    }
  }
`;

export const VALIDATE_ZIP_CODE = gql`
  query validateZipCode($zip: String!) {
    validateZipCode(zip: $zip) {
      isValid
      errors {
        message
      }
    }
  }
`;

export const GET_VIDEO_METADATA = gql`
  query getVideoMetadata($id: String!, $type: String!) {
    getVideoMetadata(id: $id, type: $type) {
      duration
      thumbnails
    }
  }
`;

export const GET_USER_FABRIC_FAVORITES = gql`
  query getUserFabricFavorites {
    userFabricFavs: getUserFabricFavorites
  }
`;
export const GET_CUSTOM_LIST_PRODUCT_DATA = gql`
  query getCustomListProductData($slug: String, $type: String) {
    getCustomListProductData(slug: $slug, type: $type)
  }
`;

export const GET_CUSTOM_LIST_PRODUCT_DATA_V2 = gql`
  query getCustomProductListDataV2(
    $slug: String
    $productList: [ProductListInput]
    $filterByStock: Boolean
    $sortByPopularity: Boolean
    $limit: Int
    $refreshCache: Boolean
  ) {
    getCustomProductListDataV2(
      limit: $limit
      slug: $slug
      productList: $productList
      filterByStock: $filterByStock
      sortByPopularity: $sortByPopularity
      refreshCache: $refreshCache
    ) {
      products {
        attributes {
          id
          values
        }
        id
        name
        path
        productFamily
        productLabel
        productType
        quickship
        rangeAttributes {
          id
          value
        }
        secondaryImage
        secondaryImageCropData
        slug
        sortOrder {
          featured
          newest
          popularity
        }
        variants {
          image
          label
          minPrice
          originalPrice
          path
          price
          quickship
          isOutOfStock
          isFlashSale
          sku
          slug
          tier
        }
        variantsTotal
      }
      totalProductCount
      upholsteryOptions {
        color
        id
        label
        thumbnail
      }
    }
  }
`;

export const VALIDATE_PRINT_CAMPAIGN = gql`
  query validatePrintCampaign($landedOn: String!) {
    validatePrintCampaign(landedOn: $landedOn) {
      valid
      couponCode
      couponType
      couponDiscount
      expiration
    }
  }
`;

export const GET_PRODUCTS_ORDER_ETA = gql`
  query getOrderETAForProductIds($productIds: [Int]) {
    getOrderETAForProductIds(productIds: $productIds)
  }
`;

export const GET_UJET_AUTH = gql`
  query {
    getUJETAuthentication {
      token
    }
  }
`;

export const GET_CLOSEST_STORE_ZIPCODE = gql`
  query getClosestStoreFromZipcode($zipcode: String) {
    getClosestStoreFromZipcode(zipcode: $zipcode)
  }
`;

export const GET_DESIGN_SERVICES_BYNDER_IMAGES = gql`
  query getDSQuizBynderImages {
    getDSQuizBynderImages {
      error
      images {
        id
        name
        tags
        image
        height
        width
        props {
          style
          file_type
          color_palette
        }
      }
    }
  }
`;

export const GET_IN_STORE_DESIGN_SERVICE_QUIZ = gql`
  query getInStoreDesignServiceQuiz($storeName: String!) {
    getInStoreDesignServiceQuiz(storeName: $storeName) {
      errors {
        code
        field
        message
      }
      quiz {
        style_photos_questions
        style_photos_answers
        style_more_photos_questions
        style_more_photos_answers
        style_color_palette_questions
        style_color_palette_answers
        style_sofas_questions
        style_sofas_answers
        style_results_questions
      }
    }
  }
`;

export const GET_IN_STORE_DESIGN_SERVICE_QUIZ_2 = gql`
  query getInStoreDesignServiceQuizPart2($storeName: String!) {
    getInStoreDesignServiceQuizPart2(storeName: $storeName) {
      errors {
        code
        field
        message
      }
      quiz
      quizId
    }
  }
`;

export const GET_IN_STORE_DESIGN_SERVICE_QUIZ_3 = gql`
  query getInStoreDesignServiceQuizPart3($kustomerId: String!) {
    getInStoreDesignServiceQuizPart3(kustomerId: $kustomerId) {
      errors {
        code
        field
        message
      }
      quiz
      quizId
    }
  }
`;

export const GET_IN_STORE_DESIGN_SERVICE_QUIZ_BY_KUSTOMER_ID = gql`
  query getInStoreDesignServiceQuizByKustomerId($kustomerId: String!) {
    getInStoreDesignServiceQuizByKustomerId(kustomerId: $kustomerId) {
      errors {
        code
        field
        message
      }
      rooms {
        room
        type
        roomName
      }
      quizId
      kustomerId
      email
      firstName
      lastName
    }
  }
`;

export const GET_S3_PRESIGNED_URL = gql`
  query getS3presignedUrl($key: String!, $section: String!) {
    getS3presignedUrl: getS3presignedUrl(key: $key, section: $section) {
      success
      url
      error
    }
  }
`;

export const GET_CONTENTFUL_ENTRIES = gql`
  query getContentfulEntries(
    $contentType: String!
    $categories: String
    $select: String
    $limit: Int!
    $order: String!
    $skip: Int!
  ) {
    getContentfulEntries(
      contentType: $contentType
      categories: $categories
      select: $select
      limit: $limit
      order: $order
      skip: $skip
    )
  }
`;

export const GET_BLOG_HOME_PAGE = gql`
  query getBlogHomePage($blogUrl: String!) {
    getBlogHomePage(blogUrl: $blogUrl) {
      featuredPostsConfig {
        imageProps {
          src
          alt
          blogImage
        }
        headLine {
          content
        }
        bodyText {
          content
        }
        primaryButton {
          linkTo
          content
        }
        imageOrientation {
          content
        }
      }
      categoryPosts {
        slug
        text
        postsConfig {
          slug
          title
          body
          categories
          description
          featuredImage
          metaDescription
        }
      }
    }
  }
`;

export const GET_CATEGORY_PAGE = gql`
  query getCategoryPage(
    $limit: Int
    $slug: String!
    $zipCode: String
    $stateCode: String
    $regionId: String
    $refreshCache: Boolean
  ) {
    getCategoryPage(
      limit: $limit
      slug: $slug
      zipCode: $zipCode
      stateCode: $stateCode
      regionId: $regionId
      refreshCache: $refreshCache
    ) {
      filters {
        id
        label
        options {
          id
          label
        }
      }
      pageHtmlTitle
      pageId
      pageLongDescription
      pageLongName
      pageMetaDescription
      pageName
      products {
        campaign
        attributes {
          id
          values
        }
        id
        name
        path
        productFamily
        productLabel
        productType
        quickship
        rangeAttributes {
          id
          value
        }
        secondaryImage
        secondaryImageCropData
        slug
        sortOrder {
          featured
          newest
          popularity
        }
        variants {
          image
          label
          minPrice
          originalPrice
          path
          price
          quickship
          isOutOfStock
          isFlashSale
          sku
          slug
          tier
        }
        variantsTotal
      }
      totalProductCount
      upholsteryOptions {
        color
        id
        label
        thumbnail
      }
    }
  }
`;

export const GET_PRODUCTS_STATIC_DATA = gql`
  query getProductsStaticData(
    $limit: Int
    $cacheKey: String
    $productIds: [Int!]!
    $zipCode: String
    $stateCode: String
    $regionId: String
    $showSearchProductsOnly: Boolean
  ) {
    getProductsStaticData(
      limit: $limit
      cacheKey: $cacheKey
      productIds: $productIds
      zipCode: $zipCode
      stateCode: $stateCode
      regionId: $regionId
      showSearchProductsOnly: $showSearchProductsOnly
    ) {
      filters {
        id
        label
        options {
          id
          label
        }
      }
      pageHtmlTitle
      pageId
      pageLongDescription
      pageLongName
      pageMetaDescription
      pageName
      products {
        campaign
        attributes {
          id
          values
        }
        id
        name
        path
        productFamily
        productLabel
        productType
        quickship
        rangeAttributes {
          id
          value
        }
        secondaryImage
        secondaryImageCropData
        slug
        sortOrder {
          featured
          newest
          popularity
        }
        variants {
          image
          label
          originalPrice
          path
          price
          quickship
          isOutOfStock
          sku
          slug
          tier
        }
        variantsTotal
      }
      totalProductCount
      upholsteryOptions {
        color
        id
        label
        thumbnail
      }
    }
  }
`;

const queries = {
  GET_CART_SHIPPING_GROUPS,
  GET_CART_DELIVERY_DATES,
  GET_CART,
  GET_FAVORITED_PRODUCTS,
  GET_NAVBAR_MENUS,
  GET_CUSTOMER_ORDERS,
  GET_ACCOUNT_INFO,
  GET_TREE_COUNT,
  GET_ACTIVE_PROMOTION_BANNER,
  GET_NEWEST_ARRIVALS,
  GET_FABRIC_DATA,
  GET_FABRIC_REVIEWS,
  GET_SITEMAP_LINKS,
  GET_FABRICS_BY_IDS,
  GET_CUSTOMER_REVIEWS,
  GET_ALL_PRODUCT_TYPES,
  GET_ALL_PRODUCTS_TREE_COUNT,
  GET_PRODUCT_VISUALIZATION_OPTIONS,
  GET_IS_PRODUCT_FAVOURITE,
  GET_JOYBIRD_INSTAGRAM_IMAGES,
  GET_BRAINTREE_CLIENT_TOKEN,
  GET_CHECKOUT_SUMMARY,
  GET_RECENTLY_VIEWED,
  GET_PROTECTION_PLAN_TYPES,
  GET_FAVORITED_PRODUCTS_FULL,
  GET_PRODUCT_MINIMAL_LAYOUT,
  GET_USER_FABRIC_FAVORITES,
  GET_UJET_AUTH,
  GET_FABRIC_GALLERY_DATA,
  GET_DESIGN_SERVICES_BYNDER_IMAGES,
  GET_IN_STORE_DESIGN_SERVICE_QUIZ,
  GET_IN_STORE_DESIGN_SERVICE_QUIZ_2,
  GET_IN_STORE_DESIGN_SERVICE_QUIZ_3,
  GET_S3_PRESIGNED_URL,
  GET_CATEGORY_PAGE,
  GET_FAVORITED_PRODUCT_IDS,
};

export default queries;
